
import AOS from "aos";
// import BlogSection from "../components/BlogSection.vue";
import "aos/dist/aos.css";
import Modal from "../components/Forms/ContactForm/Modal.vue";
import homePageData from "../data/homePage";
import Customers from "../components/customers/index.vue";
import Solutions from "../components/solutions/index.vue";

export default {
  name: "IndexPage",
  components: {
    // BlogSection,
    Modal,
    Customers,
    Solutions,
  },
  head() {
    return {
      title: "ابر زس: ارائه دهنده سرویس‌های ابری",
      meta: [
        {
          name: "description",
          content:
            "ابر زس فراهم آورنده بهترین زیرساخت‌های ابری با طیف گسترده‌ای از خدمات رایانش ابری و سرویس‌های ابری به صورت سازمانی و عمومی است.",
        },
        {
          property: "og:locale",
          content: "fa",
        },
        {
          property: "og:title",
          content: "ابر زس: ارائه دهنده سرویس‌های ابری",
        },
        {
          property: "og:url",
          content: "https://xaas.ir",
        },
        {
          property: "og:description",
          content:
            "ابر زس فراهم آورنده بهترین زیرساخت‌های ابری با طیف گسترده‌ای از خدمات رایانش ابری و سرویس‌های ابری به صورت سازمانی و عمومی است.",
        },
        {
          property: "article:publisher",
          content: "https://www.facebook.com/abrxaas",
        },
        {
          name: "twitter:card",
          content: "summary",
        },
        {
          name: "twitter:url",
          content: "https://xaas.ir",
        },
        {
          name: "twitter:creator",
          content: "@XaaSCloud",
        },
        {
          name: "twitter:title",
          content: "ابر زس: ارائه دهنده سرویس‌های ابری",
        },
        {
          name: "twitter:description",
          content: "ابر زس",
        },
        {
          name: "format-detection",
          content: "telephone=no",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://xaas.ir",
        },
      ],
      script: [
        {
          type: "application/ld+json",
          json: {
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            name: "ابر زس",
            image: "https://xaas.ir/_nuxt/img/logoz.098e72e.svg",
            "@id": "",
            url: "https://xaas.ir/",
            telephone: "021 9107 8149",
            address: {
              "@type": "PostalAddress",
              streetAddress: "West Hoveyzeh",
              addressLocality: "Tehran",
              postalCode: "",
              addressCountry: "IR",
            },
            openingHoursSpecification: {
              "@type": "OpeningHoursSpecification",
              dayOfWeek: [
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
                "Sunday",
              ],
            },
            opens: "00:00",
            closes: "23:59",
            sameAs: [
              "https://mobile.twitter.com/xaas_ir",
              "https://www.instagram.com/xaascloud/",
              "https://ir.linkedin.com/company/xaas-cloud-computing",
              "https://xaas.ir/",
            ],
          },
        },
      ],
    };
  },
  data() {
    return {
      logos: homePageData.logos,
      tab: 0,
      tabItems: homePageData.tabItems,
      dataBlog: homePageData.dataBlog,
      FormTitle: "ثبت درخواست ارتباط با واحد فروش",
      contactFormSubject: "datacenter",
      isAgent: false,
      targetDate: new Date("2025-02-04T15:00:00").getTime(), // Set your target date here
      countdown: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
    };
    return {};
  },
  mounted() {
    AOS.init();
    this.updateCountdown();
    this.intervalId = setInterval(this.updateCountdown, 1000);
  },
  methods: {
    handleOpenRebranding() {
      window.open("https://lahzenegar.com/XaaS_Cloud/Event", "_blank");
    },
    showForm(title, subject = null, isAgent) {
      this.FormTitle = title;
      this.contactFormSubject = subject;
      this.isAgent = isAgent;
      this.$refs.contactForm.show();
    },
    hanldeClickSocials(link) {
      window.open(link, "_blank");
    },
    updateCountdown() {
      const now = new Date().getTime();
      const distance = this.targetDate - now;

      if (distance <= 0) {
        clearInterval(this.intervalId);
        this.countdown = { days: 0, hours: 0, minutes: 0, seconds: 0 };
        return;
      }

      this.countdown.days = Math.floor(distance / (1000 * 60 * 60 * 24));
      this.countdown.hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      this.countdown.minutes = Math.floor(
        (distance % (1000 * 60 * 60)) / (1000 * 60)
      );
      this.countdown.seconds = Math.floor((distance % (1000 * 60)) / 1000);
    },
  },
  computed: {
    isDesktop() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
};
